import main from './main.js';
//import isotope from './isotope/isotope.js';
import jqueryPlugins from './jquery-plugins/index.js';
export default () => {
  global.$ = require( "jquery" );
  global.jQuery = $;
  require( "jquery-ui" );
  require( "bootstrap" );
  require( "flexslider" );
  jqueryPlugins();
  //isotope();
  main();
};