export default () => {
  //////////////
  //flexslider//
  //////////////
  apos.util.widgetPlayers['slideshow'] = {
    selector: '.flexslider',
    player: function(el) {
      if (jQuery().flexslider) {
        var $introSlider = jQuery(".intro_section .flexslider");
        $introSlider.each(function(index){
          var $currentSlider = jQuery(this);
          var data = $currentSlider.data();
          var nav = (data.nav !== undefined) ? data.nav : true;
          var dots = (data.dots !== undefined) ? data.dots : true;

          $currentSlider.flexslider({
            animation: "slide",
            pauseOnHover: true, 
            useCSS: true,
            controlNav: dots,   
            directionNav: nav,
            prevText: "",
            nextText: "",
            smoothHeight: true,
            slideshowSpeed:5000,
            animationSpeed:600,
            start: function( slider ) {
              slider.find('.slide_description').children().css({'visibility': 'hidden'});
              slider.find('.flex-active-slide .slide_description').children().each(function(index){
                var self = jQuery(this);
                var animationClass = !self.data('animation') ? 'fadeInRight' : self.data('animation');
                setTimeout(function(){
                  self.addClass("animated "+animationClass);
                }, index*200);
              });
              slider.find('.flex-control-nav').find('a').each(function() {
                jQuery( this ).html('0' + jQuery( this ).html());
              })
            },
            after :function( slider ){
              slider.find('.flex-active-slide .slide_description').children().each(function(index){
                var self = jQuery(this);
                var animationClass = !self.data('animation') ? 'fadeInRight' : self.data('animation');
                setTimeout(function(){
                  self.addClass("animated "+animationClass);
                }, index*200);
              });
            },
            end :function( slider ){
              slider.find('.slide_description').children().each(function() {
                var self = jQuery(this);
                var animationClass = !self.data('animation') ? 'fadeInRight' : self.data('animation');
                self.removeClass('animated ' + animationClass).css({'visibility': 'hidden'});
                  // jQuery(this).attr('class', '');
              });
            },

          })
          //wrapping nav with container - uncomment if need
          .find('.flex-control-nav')
          .wrap('<div class="container-fluid nav-container"/>')
        }); //intro_section flex slider

        jQuery(".flexslider").each(function(index){
          var $currentSlider = jQuery(this);
          //exit if intro slider already activated 
          if ($currentSlider.find('.flex-active-slide').length) {
            return;
          }
          $currentSlider.flexslider({
            animation: "fade",
            useCSS: true,
            controlNav: true,   
            directionNav: true,
            prevText: "",
            nextText: "",
            smoothHeight: false,
            slideshowSpeed:5000,
            animationSpeed:800,
          })
        });
      }
    }
  }
}
