export default () => {
  apos.util.widgetPlayers['contact-form'] = {
    selector: '.contact-form',
    player: function (el) {

      function addRecaptchaScript (siteKey) {
        const container = document.querySelector('[data-apos-refreshable]') || document.body;
        const recaptchaScript = document.createElement('script');

        recaptchaScript.src = apos.http.addQueryToUrl('https://www.google.com/recaptcha/api.js', {
          render: siteKey
        });

        recaptchaScript.setAttribute('data-apos-recaptcha-script', '');
        recaptchaScript.setAttribute('async', '');
        recaptchaScript.setAttribute('defer', '');
        container.appendChild(recaptchaScript);
      }

      addRecaptchaScript(jQuery('#contact_form_submit').attr('data-recaptcha-sitekey'))

      //contact form processing
      jQuery('form.contact-form').on('submit', async function( e ){
        e.preventDefault();
        var $form = jQuery(this);
        var token;
        jQuery($form).find('span.contact-form-respond').remove();

        //checking on empty values
        jQuery($form).find('[aria-required="true"], [required]').each(function(index) {
          var $thisRequired = jQuery(this);
          if (!$thisRequired.val().length) {
            $thisRequired
              .addClass('invalid')
              .on('focus', function(){
                $thisRequired
                  .removeClass('invalid');
              });
          }
        });
        //if one of form fields is empty - exit
        if ($form.find('[aria-required="true"], [required]').hasClass('invalid')) {
          return;
        }

        // RECAPTCHA PROCESS
        try {
          token = await grecaptcha.execute(jQuery('#contact_form_submit').attr('data-recaptcha-sitekey'), { action: 'submit' });
        } catch (error) {
          console.error('reCAPTCHA execution error:', error);
          return null;
        }

        // SEND MAIL REQUEST
        var request = $form.serializeArray();
        request.push({name: "token", value: token}, {name: "typeForm", value: jQuery('#contacts').attr('data-type-form')});
        var ajax = jQuery.post( "contact/sendMail", request )
        .done(function( data ) {
          jQuery($form).find('[type="submit"]').attr('disabled', false).parent().append('<span class="contact-form-respond blockquote">'+data.msg+'</span>');
          //cleaning form
          var $formErrors = $form.find('.form-errors');
          if ( !$formErrors.length ) {
            $form[0].reset();
          }
        })
        .fail(function( data ) {
          jQuery($form).find('[type="submit"]').attr('disabled', false).parent().append('<span class="contact-form-respond highlight">Le mail ne peut être envoyé.</span>');
        })
      });
    }
  }    
};